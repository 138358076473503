export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'dashboard',
    label: 'Dashboard',
  },
  {
    path: 'projects',
    label: 'Dự án',
  },
  {
    path: 'blog',
    label: 'Tin tức',
  },
  {
    path: 'clients',
    label: 'Đối tác',
  },
  {
    path: 'contact',
    label: 'Liên hệ',
  },
];
